/**Packages */
import { isString } from "lodash";

/**
 *
 * @param {string} str - string to be trimmed
 * @returns {string} trimmed string
 */
export const getStringTrimmedStartEnd = (str = "") => {
    if (!isString(str)) return "";

    return str.trimStart().trimEnd();
};
