/**Packages */
import moment from "moment";
import i18n from "../i18n/i18n";
import { isEmpty } from "lodash";

/**Constants */
import {
    INDEX_URL,
    COMPONENT_ENUM,
    YEARS_ENUM,
    MENU_TYPE_ENUM,
    SPECIAL_DIETS,
    DIET_ITALY,
    DIET_ITALY_SUFFIX,
    N_MAX_NEWS_HOME,
    ENABLED_CHATS,
    N_MAX_NEWS_HOME_CHILDREN,
    DEFAULT_ENABLED_LANGUAGES,
    PAGES_WHERE_DISABLE_OVERFLOW,
    USER_RATE_TAG,
    N_MAX_ACTIVITIES_HOME,
} from "./constants";

/**Helpers */
import { getCurrentUser } from "./state";
import {
    getIfRandomicNewsBackgroundIsEnabled,
    getMinCanteenIDNumber,
    getMaxCanteenIDNumber,
    getIfSoHappy,
    getIfSoMeal,
} from "./enviroment";
import { getTranslatedString } from "./translation";
import { getStringTrimmedStartEnd } from "./string";

/**Icons */
export const getIconByYear = (year, useActiveState = false, active = false) => {
    if (!year) year = "";
    let htmlToReturn = {};
    switch (YEARS_ENUM[year.toUpperCase()]) {
        case 0: {
            /** (4M-1) */
            /** NIDO */
            const className = useActiveState
                ? active
                    ? "icon2-TRITURADA icon active"
                    : "icon2-TRITURADA icon"
                : "icon2-TRITURADA icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                </span>
            );
            break;
        }
        case 1:
        case 2: {
            /** (1-3) */
            /** (3-6) */
            const className = useActiveState ? (active ? "icon-user icon active" : "icon-user icon") : "icon-user icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                </span>
            );
            break;
        }
        case 3:
        case 4: {
            /** (7-9) */
            /** (10-13) */
            const className = useActiveState ? (active ? "icon-user icon active" : "icon-user icon") : "icon-user icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path8"></span>
                </span>
            );
            break;
        }
        case 5: {
            /** (>13) || '' */
            const className = useActiveState ? (active ? "icon-user icon active" : "icon-user icon") : "icon-user icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                </span>
            );
            break;
        }

        default: {
            /** DEFAULT */
            const className = useActiveState ? (active ? "icon-user icon active" : "icon-user icon") : "icon-user icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                </span>
            );
            break;
        }
    }

    return htmlToReturn;
};
export const getIconByDiet = (type, useActiveState = false, active = false) => {
    if (!type) type = "";
    let htmlToReturn = {};

    switch (MENU_TYPE_ENUM[type.toUpperCase()]) {
        case 0: {
            /**MENU GENERAL - BASAL*/
            const className = useActiveState ? (active ? "icon-dish icon active" : "icon-dish icon") : "icon-dish icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                </span>
            );
            break;
        }
        case 1: {
            /**INFANTIL PURES*/
            const className = useActiveState
                ? active
                    ? "icon2-INFANTIL-PURE icon active"
                    : "icon2-INFANTIL-PURE icon"
                : "icon2-INFANTIL-PURE icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                </span>
            );
            break;
        }
        case 2: {
            /**INFANTIL SOLIDO*/
            const className = useActiveState
                ? active
                    ? "icon2-INFANTIL-SOLIDO icon active"
                    : "icon2-INFANTIL-SOLIDO icon"
                : "icon2-INFANTIL-SOLIDO icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                </span>
            );
            break;
        }
        case 3: {
            /**SIN GLUTEN*/
            const className = useActiveState
                ? active
                    ? "icon-grain icon active"
                    : "icon-grain icon"
                : "icon-grain icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                </span>
            );
            break;
        }
        case 4: {
            /**SIN LACTOSA*/
            const className = useActiveState
                ? active
                    ? "icon-milk-bottle icon active"
                    : "icon-milk-bottle icon"
                : "icon-milk-bottle icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                </span>
            );
            break;
        }
        case 5: {
            /**SIN HUEVO*/
            const className = useActiveState
                ? active
                    ? "icon-egges icon active"
                    : "icon-egges icon"
                : "icon-egges icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                </span>
            );
            break;
        }
        case 6: {
            /**SIN LEGUMBRES*/
            const className = useActiveState ? (active ? "icon-buns icon active" : "icon-buns icon") : "icon-buns icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                    <span className="path11" />
                    <span className="path12" />
                    <span className="path13" />
                    <span className="path14" />
                    <span className="path15" />
                </span>
            );
            break;
        }
        case 7: {
            /**SIN PROTEINA DE LECHE DE VACA*/
            const className = useActiveState ? (active ? "icon-cow icon active" : "icon-cow icon") : "icon-cow icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                    <span className="path11" />
                    <span className="path12" />
                    <span className="path13" />
                </span>
            );
            break;
        }
        case 8: {
            /**SIN PESCADO/MARISCO*/
            const className = useActiveState
                ? active
                    ? "icon-sea-foods icon active"
                    : "icon-sea-foods icon"
                : "icon-sea-foods icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                </span>
            );
            break;
        }
        case 9: {
            /**HIPOCALORICA*/
            const className = useActiveState
                ? active
                    ? "icon2-HIPOCALORICO icon active"
                    : "icon2-HIPOCALORICO icon"
                : "icon2-HIPOCALORICO icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                </span>
            );
            break;
        }
        case 10: {
            /**SIN FRUTOS SECOS*/
            const className = useActiveState
                ? active
                    ? "icon2-FRUTOS-SECOS icon active"
                    : "icon2-FRUTOS-SECOS icon"
                : "icon2-FRUTOS-SECOS icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                </span>
            );
            break;
        }
        case 11: {
            /**CARNE*/
            /**TERNERA*/
            const className = useActiveState
                ? active
                    ? "icon2-CARNE icon active"
                    : "icon2-CARNE icon"
                : "icon2-CARNE icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                </span>
            );
            break;
        }
        case 12: {
            /**CERDO*/
            const className = useActiveState
                ? active
                    ? "icon2-CERDO icon active"
                    : "icon2-CERDO icon"
                : "icon2-CERDO icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                    <span className="path11" />
                </span>
            );
            break;
        }
        case 13: {
            /**FRUCTOSA*/
            const className = useActiveState
                ? active
                    ? "icon2-FRUCTOSA icon active"
                    : "icon2-FRUCTOSA icon"
                : "icon2-FRUCTOSA icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                    <span className="path11" />
                    <span className="path12" />
                    <span className="path13" />
                    <span className="path14" />
                    <span className="path15" />
                </span>
            );
            break;
        }
        case 14: {
            /**MULTIALERGENO*/
            const className = useActiveState
                ? active
                    ? "icon2-MULTIALERGENO icon active"
                    : "icon2-MULTIALERGENO icon"
                : "icon2-MULTIALERGENO icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                </span>
            );
            break;
        }
        case 15: {
            /**VEGETARIANO*/
            const className = useActiveState
                ? active
                    ? "icon2-VEGETARIANO icon active"
                    : "icon2-VEGETARIANO icon"
                : "icon2-VEGETARIANO icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                </span>
            );
            break;
        }
        case 16: {
            /**TRITURADA*/
            const className = useActiveState
                ? active
                    ? "icon2-TRITURADA icon active"
                    : "icon2-TRITURADA icon"
                : "icon2-TRITURADA icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                </span>
            );
            break;
        }
        case 17: {
            /**BAJO-COLESTEROL*/
            const className = useActiveState
                ? active
                    ? "icon3-BAJO-COLESTEROL icon active"
                    : "icon3-BAJO-COLESTEROL icon"
                : "icon3-BAJO-COLESTEROL icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                </span>
            );
            break;
        }
        case 18: {
            /**ESPECIAL */
            /**MULTIALÉRGICO */
            const className = useActiveState
                ? active
                    ? "icon2-MULTIALERGENO icon active"
                    : "icon2-MULTIALERGENO icon"
                : "icon2-MULTIALERGENO icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                </span>
            );
            break;
        }
        default: {
            const className = useActiveState ? (active ? "icon-dish icon active" : "icon-dish icon") : "icon-dish icon";
            htmlToReturn = (
                <span className={className}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                </span>
            );
            break;
        }
    }

    return htmlToReturn;
};
export const getIconByComponent = component => {
    if (!component) component = "default";
    let htmlToReturn = {};

    switch (COMPONENT_ENUM[component.toUpperCase()]) {
        case 0: {
            /** PRIMEROS */
            htmlToReturn = (
                <span className="icon-bowl-spoon recipe__icon">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                </span>
            );
            break;
        }
        case 1:
        case 9: {
            /** SEGUNDOS */
            /**PIATTO UNICO */
            htmlToReturn = (
                <span className="icon-food-plate recipe__icon">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                </span>
            );
            break;
        }
        case 2: {
            /** GUARNICIONES */
            htmlToReturn = (
                <span className="icon-food-dish recipe__icon">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                </span>
            );
            break;
        }
        case 3: {
            /** POSTRES */
            htmlToReturn = (
                <span className="icon-apple recipe__icon">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                </span>
            );
            break;
        }
        case 4: {
            /** DESAYUNO */
            htmlToReturn = (
                <div className="text-center">
                    <span className="icon2-CUP recipe__icon">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                        <span className="path7"></span>
                    </span>
                </div>
            );
            break;
        }
        case 5: {
            /** MERIENDA */
            htmlToReturn = (
                <div className="text-center">
                    <span className="icon2-TRIANGLE recipe__icon">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                    </span>
                </div>
            );
            break;
        }
        case 6: {
            /** ENSALADA/ENSALADAS */
            htmlToReturn = (
                <span className="icon2-VEGETARIANO recipe__icon" style={{ fontSize: "65px" }}>
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                </span>
            );
            break;
        }
        case 7: {
            /** PRODOTTO DA FORNO */
            htmlToReturn = (
                <span className="icon2-BREAD recipe__icon">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                </span>
            );
            break;
        }
        case 8: {
            /**BEVANDE */
            htmlToReturn = <span className="icon-bevande image__icon recipe__icon" />;
            break;
        }
        case 10: {
            /**PANINOTECA */
            htmlToReturn = <span className="icon-panino image__icon recipe__icon" />;
            break;
        }
        case 11: {
            /**ENTRANTE */
            htmlToReturn = <span className="icon-entrante image__icon recipe__icon" />;
            break;
        }
        case 12: {
            /**PAN */
            htmlToReturn = <span className="icon-pan image__icon recipe__icon" />;
            break;
        }
        default: {
            /** DEFAULT */
            htmlToReturn = <div></div>;
            break;
        }
    }

    return htmlToReturn;
};
export const getClassNameByComponent = component => {
    if (!component) component = "";
    let componentCSS = "";

    switch (COMPONENT_ENUM[component.toUpperCase()]) {
        case 0: {
            /** PRIMEROS */
            componentCSS = "primo";
            break;
        }
        case 1: {
            /** SEGUNDOS */
            componentCSS = "secondo";
            break;
        }
        case 2: {
            /** GUARNICIONES */
            componentCSS = "contorno";
            break;
        }
        case 3: {
            /** POSTRES */
            componentCSS = "dolce";
            break;
        }
        case 4: {
            /** DESAYUNO */
            componentCSS = "";
            break;
        }
        case 5: {
            /** MERIENDA */
            componentCSS = "";
            break;
        }
        case 6: {
            /** ENSALADA/ENSALADAS */
            componentCSS = "insalata";
            break;
        }
        case 7: {
            /** PRODOTTO DA FORNO */
            componentCSS = "forno";
            break;
        }
        case 8: {
            /** BEVANDE */
            componentCSS = "bevande";
            break;
        }
        case 9: {
            /** piatto_unico */
            componentCSS = "piatto_unico";
            break;
        }
        case 10: {
            /** paninoteca */
            componentCSS = "paninoteca";
            break;
        }
        default: {
            /** DEFAULT */
            componentCSS = "";
            break;
        }
    }

    return componentCSS;
};

/**Various Utils */
export const getPrefixxedPage = (newPage = "") => {
    if (!newPage || newPage === "") return INDEX_URL;

    return `${INDEX_URL}${newPage}`;
};
export const getActivePage = () => {
    return window.location.pathname;
};
export const disabledOverflowY = () => {
    const activePage = getActivePage();
    if (!activePage || activePage === "") return false;
    return PAGES_WHERE_DISABLE_OVERFLOW.includes(activePage);
};
export const openUrl = (url = null) => {
    if (!url) return;
    return window.open(url);
};
export const openUrlInSamePage = (url = null) => {
    if (!url) return;
    return window.open(url, "_self");
};
export const checkString = value => {
    if (!typeof value === "string" || !value || value === "") return false;

    return true;
};
export const checkIfProfileEquals = (currentProfile, selectedProfile) => {
    if (!currentProfile || isEmpty(currentProfile)) return false;
    if (!selectedProfile || isEmpty(selectedProfile)) return false;

    return currentProfile.hasOwnProperty("docId") && selectedProfile.hasOwnProperty("docId")
        ? currentProfile.docId === selectedProfile.docId
        : false;
};
export const getPreferredProfile = profiles => {
    if (!profiles || profiles.length === 0) return null;

    const profileFavorite = profiles.find(profile => {
        return Object.prototype.hasOwnProperty.call(profile, "favorite") && profile.favorite;
    });

    return profileFavorite ? profileFavorite : profiles[0];
};
export const checkIfUserHasProfileWithPassedCid = (profiles = [], currentCid = null) => {
    const out = {
        success: false,
        data: null,
    };

    if (!profiles || profiles.length === 0 || !currentCid || currentCid === "") return out;

    const foundedItem = profiles.find(profile => {
        const { cid } = profile;

        if (cid && cid === currentCid) {
            return true;
        }

        return false;
    });

    if (foundedItem) {
        out.success = true;
        out.data = foundedItem;
    }

    return out;
};
export const convertDietProfileIntoDietMenu = diet => {
    return {
        occasion_id: diet.occasion_id,
        occasion_name: diet.occasion_name,
        diet_id: diet.id,
        diet_name: diet.name,
    };
};
export const convertDietMenuIntoDietProfilePartial = diet => {
    return {
        occasion_id: diet.occasion_id,
        occasion_name: diet.occasion_name,
        id: diet.diet_id,
        name: diet.diet_name,
    };
};
export const getEmailDomain = email => {
    return email ? email.split("@")[1] : "";
};
export const sortByPosAscending = (a, b) => {
    return a.pos - b.pos;
};
export const sortByPosDescending = (a, b) => {
    return b.pos - a.pos;
};
export const getPositionInArrOfValue = (arr = [], propToCheck = "id", valueToCheck = null) => {
    if (!arr || !Array.isArray(arr) || arr.length <= 0 || !valueToCheck) return -1;

    return arr.findIndex(itm => {
        return propToCheck ? itm[propToCheck] === valueToCheck : itm === valueToCheck;
    });
};

export const getCidsByProfiles = profiles => {
    return profiles?.length > 0
        ? Array.from(new Set(profiles.map(profile => profile.cid))).filter(cid => {
              return checkIsCanteenEnabled(cid);
          })
        : [];
};
export const getCidsFilteredByRange = fullCidsData => {
    const cids =
        fullCidsData?.length > 0
            ? Array.from(new Set(fullCidsData.map(fullCid => fullCid.cid))).filter(cid => {
                  return checkIsCanteenEnabled(cid);
              })
            : [];

    return cids.length > 0
        ? cids.map(currentCid => {
              if (currentCid) {
                  return fullCidsData.find(fullCidData => {
                      const { cid } = fullCidData;

                      return cid === currentCid;
                  });
              }
          })
        : [];
};
export const getParameterByUrl = (param = "m") => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (param === "m") {
        return params[param] ? params[param].split("C_")[1] : "";
    } else {
        return params[param] ? params[param] : "";
    }
};
export const getCidByState = (state = "") => {
    if (!state || state === "") return "";

    return state.split("C_")[1];
};
export const getFormattedCid = (cid = null) => {
    if (!cid || cid === "") return null;

    return typeof cid === "string" && cid.indexOf("C_") === -1
        ? "C_" + cid
        : typeof cid === "string" && cid.indexOf("C_") !== -1
          ? cid
          : "" + cid;
};
export const checkIfStringIsHtml = str => {
    return str.indexOf("</") !== -1;
};

export const getYearHtml = (year, language = null, t, useLittleVersion = false) => {
    year = year ? year.toUpperCase() : "";
    if (!language || language === "") language = getCurrentLanguage();

    const textHtml = t("years." + year.toUpperCase()).substring(1, t("years." + year.toUpperCase()).length - 1),
        className = `item${useLittleVersion ? " little" : ""}`;

    /*
        <div className="default-heading">{YEARS_NAME_ENUM[year.toUpperCase()][language]}</div>
    */
    return (
        <div id={year} key={year} className={className} data={JSON.stringify(year)}>
            {/**
                    <div className="small-text-area top">
                        <div className="text">{year}</div>
                    </div>
                 */}
            {getIconByYear(year)}
            <div
                className="default-heading"
                dangerouslySetInnerHTML={{
                    __html: textHtml,
                    //__html: "<h2>" + year + "</h2>",
                }}
            />
            {/*
                        /** 
                <div className="small-text-area bottom">
                    {
                    <div className="text">{t('scegli')}</div>
                    }
                    <div className="text">{''}</div>
                </div>
                        */}
        </div>
    );
};
const getCorrectedDietYear = dietNameFormatted => {
    const DEFAULT_YEAR_TO_RETURN = "(7-9)";

    if (!dietNameFormatted || dietNameFormatted === "") return "";
    const splittedStringBySpace = dietNameFormatted.split(" ");

    /**
     * case "scuole italiane" -> example  "nido"
     */
    if (DIET_ITALY_SUFFIX.includes(dietNameFormatted.toLowerCase())) {
        return DIET_ITALY_SUFFIX.includes(dietNameFormatted.toLowerCase()) ? dietNameFormatted : DEFAULT_YEAR_TO_RETURN;
    }

    /**
     * case "scuole italiane" -> example "scuola inf"
     */
    if (splittedStringBySpace.length > 1 && dietNameFormatted.indexOf("(") === -1) {
        const yearToReturn = dietNameFormatted
            .substr(dietNameFormatted.lastIndexOf(" "), dietNameFormatted.length)
            .trim();
        return DIET_ITALY_SUFFIX.includes(yearToReturn.toLowerCase()) ? yearToReturn : DEFAULT_YEAR_TO_RETURN;
    }

    /**
     * case "scuole spagna"
     */
    if (dietNameFormatted.indexOf("(") !== -1) {
        return dietNameFormatted.substr(dietNameFormatted.indexOf(" ("), dietNameFormatted.length - 1).trim();
    }

    return DEFAULT_YEAR_TO_RETURN;
};
export const getYearByDietName = diet => {
    const name = diet?.diet_name
        ? getStringTrimmedStartEnd(diet.diet_name[process.env.REACT_APP_DEFAULT_LNG]?.toLowerCase())
        : diet?.name
          ? getStringTrimmedStartEnd(diet.name[process.env.REACT_APP_DEFAULT_LNG]?.toLowerCase())
          : "";

    return getCorrectedDietYear(name);
};
const customRules = dietNameFormattedLowered => {
    return SPECIAL_DIETS.includes(dietNameFormattedLowered);
};
const getCorrectedDietName = dietNameFormatted => {
    if (!dietNameFormatted || dietNameFormatted === "") return "";

    /**Nome (7-9) ecc. */
    if (dietNameFormatted.indexOf(" (") !== -1) {
        return dietNameFormatted.substr(0, dietNameFormatted.indexOf(" (")).toUpperCase().trim();
    }

    const splittedBySpace = dietNameFormatted.split(" ");

    if (splittedBySpace.length > 1) {
        const isItaly = splittedBySpace.find(itm => {
            return DIET_ITALY_SUFFIX.includes(itm);
        });

        if (isItaly) {
            return dietNameFormatted.substr(0, dietNameFormatted.lastIndexOf(" "));
        }
    }

    return dietNameFormatted.toUpperCase().trim();
};
/**
 *
 * @param {*} diet
 * @param {*} useDefaultLanguage
 * @returns
 */
export const getDietNameFormatted = (diet, useDefaultLanguage = false) => {
    let currentLng = useDefaultLanguage
        ? getIfSoMeal()
            ? "es"
            : getIfSoHappy()
              ? "it"
              : getCurrentLanguage()
        : getCurrentLanguage();

    if (!diet || diet == {}) return "";

    const dietNameFormatted = getTranslatedString(diet.diet_name || diet.name, currentLng);
    const dietNameFormattedLowered = dietNameFormatted.toLowerCase().trim();
    const forCustomRulesName = dietNameFormattedLowered.split("(")[0].trim();

    if (customRules(forCustomRulesName)) {
        if (dietNameFormattedLowered.indexOf("basal") !== -1 || DIET_ITALY.includes(dietNameFormattedLowered)) {
            return i18n.t("menu.menu_basale");
        }

        if (dietNameFormattedLowered.indexOf("especial") !== -1) {
            return "multialérgico".toUpperCase();
        }
    }

    return getCorrectedDietName(dietNameFormattedLowered).toUpperCase();
};
export const getDietHtml = (diet, t, useLittleVersion = false) => {
    if (!diet || !diet.diet_name || !diet.diet_name[process.env.REACT_APP_DEFAULT_LNG]) {
        return null;
    }

    let nameEsPartial = getDietNameFormatted(diet, true);

    if (nameEsPartial.indexOf("/ ") !== -1) {
        nameEsPartial = nameEsPartial.split("/ ")[0];
    }

    const nameEs = nameEsPartial.trim().replace(/\s/g, ""),
        className = `item${useLittleVersion ? " little" : ""}`;
    return (
        <div id={diet.diet_id} key={diet.diet_id} className={className} data={JSON.stringify(diet)}>
            {getIconByDiet(nameEs)}
            <div className="default-heading">
                <h2>{getDietNameFormatted(diet)}</h2>
            </div>
            {/*
                <div className="small-text-area bottom">
                    <div className="text">{t('scegli')}</div>
                </div>
                */}
        </div>
    );
};
export const getNewsHtml = (news, t) => {
    const { id, title, abstract, text } = news;
    //const textToUse = abstract && abstract !== "" ? abstract : text && text !== "" ? text : "";
    const textToUse = abstract && abstract !== "" ? abstract : "";
    return (
        <div id={id} key={id} className="item" data={JSON.stringify(news)}>
            <div className="default-heading">
                {checkIfStringIsHtml(title) ? <h2 dangerouslySetInnerHTML={{ __html: title }} /> : <h2>{title}</h2>}
            </div>
            {checkIfStringIsHtml(textToUse) ? (
                <p style={{ marginBottom: "50px" }} dangerouslySetInnerHTML={{ __html: textToUse }} />
            ) : (
                <p style={{ marginBottom: "50px" }}>{textToUse}</p>
            )}
        </div>
    );
};

/**
 * LANGUAGES UTIL
 */
export const getCurrentLanguage = () => {
    return localStorage.getItem("language") || process.env.REACT_APP_DEFAULT_LNG;
};
export const setCurrentLanguage = newLng => {
    /**
     * Setting current language in localStorage
     */
    if (!newLng || newLng === "") return;
    localStorage.setItem("language", newLng);
    i18n.changeLanguage(newLng);
};
export const getFullLanguageName = (currentLanguage, t) => {
    if (currentLanguage) {
        return t(`languages.${currentLanguage}`);
    }

    return "";
};
export const getEnabledLanguages = languages => {
    if (!languages || (Array.isArray(languages) && languages.length === 0)) return DEFAULT_ENABLED_LANGUAGES;

    return languages.map(itm => {
        return itm.CODE.toLowerCase();
    });
};
export const getCanteenLanguagesFormatted = languages => {
    if (!languages || languages.length === 0) return [];

    return languages.map(itm => {
        return itm.CODE.toLowerCase();
    });
};
export const checkIfCurrentLanguageIsEnabled = (currentLng = getCurrentLanguage(), languagesCode = []) => {
    if (languagesCode?.length > 0) {
        return languagesCode.includes(currentLng);
    }

    return true;
};

/**
 *  CREATE-PROFILE PAGE
 * */
export const getYearCreateProfileHtml = (year, language = null, t, isActive = false) => {
    year = year ? year.toUpperCase() : "";
    if (!language || language === "") language = getCurrentLanguage();

    const textHtml = t("create_profile.years." + year.toUpperCase()).substring(
        1,
        t("create_profile.years." + year.toUpperCase()).length - 1,
    );
    return (
        <div id={year} key={year} className="column-details" data={JSON.stringify(year)}>
            <div className="column-details">
                {getIconByYear(year, true, isActive)}
                <div
                    className="default-heading"
                    dangerouslySetInnerHTML={{
                        __html: textHtml,
                    }}
                />
            </div>
        </div>
    );
};
export const getDietCreateProfileHtml = (diet, t, isActive = false) => {
    let nameEsPartial = getDietNameFormatted(diet, true);

    if (nameEsPartial.indexOf("/ ") !== -1) {
        nameEsPartial = nameEsPartial.split("/ ")[0];
    }

    const nameEs = nameEsPartial.trim().replace(/\s/g, "");
    return (
        <div id={diet.diet_id} key={diet.diet_id} className="column-details" data={JSON.stringify(diet)}>
            {getIconByDiet(nameEs, true, isActive)}
            <div className="default-heading">
                <h3>{getDietNameFormatted(diet)}</h3>
            </div>
            {/*
                <div className="small-text-area bottom">
                    <div className="text">{t('scegli')}</div>
                </div>
                */}
        </div>
    );
};
export const getYearCreateProfileHtml_profile = (year, language = null, t, isActive = false) => {
    year = year ? year.toUpperCase() : "";
    if (!language || language === "") language = getCurrentLanguage();

    const textHtml = t("create_profile.years." + year.toUpperCase()).substring(
        1,
        t("create_profile.years." + year.toUpperCase()).length - 1,
    );
    return (
        <div id={year} key={year} className="item" data={JSON.stringify(year)}>
            {getIconByYear(year, true, isActive)}
            <div
                className="default-heading"
                dangerouslySetInnerHTML={{
                    __html: textHtml.replaceAll("3", "2"),
                }}
            />
        </div>
    );
};
const getDietId = diet => {
    return diet && diet.diet_id ? diet.diet_id : diet && diet.id ? diet.id : null;
};
export const getDietCreateProfileHtml_profile = (diet, t, isActive = false) => {
    /*
    const splittedByParentesi = diet.diet_name
        ? diet.diet_name[process.env.REACT_APP_DEFAULT_LNG].split("(")
        : diet.name
        ? diet.name[process.env.REACT_APP_DEFAULT_LNG].split("(")
        : "";
        */
    let nameEsPartial = getDietNameFormatted(diet, true);

    if (nameEsPartial.indexOf("/ ") !== -1) {
        nameEsPartial = nameEsPartial.split("/ ")[0];
    }

    const nameEs = nameEsPartial.trim().replace(/\s/g, "");
    return (
        <div id={getDietId(diet)} key={`diet-box-${getDietId(diet)}`} className="item" data={JSON.stringify(diet)}>
            {getIconByDiet(nameEs, true, isActive)}
            <div className="default-heading">
                <h2>{getDietNameFormatted(diet)}</h2>
            </div>
            {/*
                <div className="small-text-area bottom">
                    <div className="text">{t('scegli')}</div>
                </div>
                */}
        </div>
    );
};

/**NEWS
 *
 * BLOCCO A1
 */
export const getNewsHome = data => {
    const lng = getCurrentLanguage();
    if (!data || data?.length === 0) return [];

    return data[0]?.news
        .map(itm => {
            return {
                id: itm.id,
                ...itm.message,
            };
        })
        .filter(fullMessage => {
            let data = {};
            if (fullMessage.translations) {
                return (
                    fullMessage.translations.subject &&
                    fullMessage.translations.subject[lng] &&
                    fullMessage.translations.subject[lng] !== ""
                );
            } else {
                return fullMessage.subject && fullMessage.subject !== "";
            }
        })
        .map(fullMessage => {
            let data = {};
            if (fullMessage.translations) {
                data = {
                    id: fullMessage.id,
                    pos: fullMessage.pos,
                    title: fullMessage.translations.subject[lng],
                    text: fullMessage.translations.text[lng],
                    abstract: fullMessage.translations.abstract
                        ? fullMessage.translations.abstract[lng]
                        : fullMessage.translations.text[lng],
                    newType: true,
                };
            } else {
                data = {
                    id: fullMessage.id,
                    pos: fullMessage.pos,
                    title: fullMessage.subject,
                    text: fullMessage.text,
                    abstract: fullMessage.text,
                    newType: false,
                };
            }

            return data;
        })
        .slice(0, N_MAX_NEWS_HOME);
};
/**Media button on click event*/
export const mediaButtonOnClick = (e, media = null) => {
    e.preventDefault();
    if (media) {
        openUrl(media);
    }
};
export const getIfMediaIsDoc = (media = "") => {
    if (!media || media === "") return null;

    const mediaExtension = [".pdf", ".docx", ".xlsx", ".xls", ".doc"];

    let isMedia = false;

    for (let index = 0; index < mediaExtension.length; index++) {
        const actualMedia = mediaExtension[index];

        if (!isMedia) {
            isMedia = media.indexOf(actualMedia) !== -1;
        }
    }

    return isMedia;
};
/**
 * BLOCCO A2
 */
export const getActivities = data => {
    const lng = getCurrentLanguage();
    if (!data || data?.length === 0) return [];

    return data[1]?.news
        .map(itm => {
            return {
                id: itm.id,
                ...itm.message,
            };
        })
        .filter(fullMessage => {
            let data = {};
            if (fullMessage.translations) {
                return (
                    fullMessage.translations.subject &&
                    fullMessage.translations.subject[lng] &&
                    fullMessage.translations.subject[lng] !== ""
                );
            } else {
                return fullMessage.subject && fullMessage.subject !== "";
            }
        })
        .map(fullMessage => {
            let data = {};
            if (fullMessage.translations) {
                data = {
                    id: fullMessage.id,
                    pos: fullMessage.pos,
                    title: fullMessage.translations.subject[lng],
                    text: fullMessage.translations.text[lng],
                    abstract: fullMessage.translations.abstract
                        ? fullMessage.translations.abstract[lng]
                        : fullMessage.translations.text[lng],
                    img: fullMessage.imageurl && !getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    file: fullMessage.imageurl && getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    newType: true,
                };
            } else {
                data = {
                    id: fullMessage.id,
                    pos: fullMessage.pos,
                    title: fullMessage.subject,
                    message: fullMessage.text,
                    abstract: fullMessage.text,
                    img: fullMessage.imageurl && !getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    file: fullMessage.imageurl && getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    newType: false,
                };
            }

            return data;
        })
        .slice(0, N_MAX_ACTIVITIES_HOME);
};
export const getFilters = filters => {
    return [...filters];
};
export const getMergedNews = news => {
    if (!news || isEmpty(news) || !Array.isArray(news) || news.length === 0) return [];

    const newsFormatted = news.map(itm => {
        if (itm.news && itm.news.length > 0) {
            return itm.news.map(newData => {
                return newData.message;
            });
        } else {
            return [];
        }
    });

    return [].concat.apply([], newsFormatted);
};
export const getNewsHtml_News = (data, onNewsClick) => {
    if (!data || data === {}) return null;

    let id = 2,
        htmlToReturn = null;

    const lng = getCurrentLanguage();

    if (!data.imageurl || data.imageurl === "" || getIfMediaIsDoc(data.imageurl)) {
        if (getIfRandomicNewsBackgroundIsEnabled()) {
            id = Math.floor(Math.random() * 2);
        } else {
            //SoHappy
            id = 1;
        }
    }

    const { translations } = data;

    const title =
        translations.subject && translations.subject[lng] ? translations.subject[lng] : "No data for lng " + lng;
    const titleEl = checkIfStringIsHtml(title) ? <p dangerouslySetInnerHTML={{ __html: title }} /> : <p>{title}</p>;

    switch (id) {
        case 0: {
            htmlToReturn = (
                <div
                    className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 grid-item clickable"
                    onClick={e => {
                        onNewsClick(e, data);
                    }}
                >
                    <div className="box-area-small">{titleEl}</div>
                </div>
            );
            break;
        }
        case 1: {
            htmlToReturn = (
                <div
                    className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 grid-item clickable"
                    onClick={e => {
                        onNewsClick(e, data);
                    }}
                >
                    <div className="circling-area">{titleEl}</div>
                </div>
            );
            break;
        }
        case 2: {
            htmlToReturn = (
                <div
                    className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 grid-item clickable"
                    onClick={e => {
                        onNewsClick(e, data);
                    }}
                >
                    <figure className="add-items">
                        <img src={data.imageurl} alt="add" />
                        <div className="info-text">{titleEl}</div>
                    </figure>
                </div>
            );
            break;
        }
        default: {
            htmlToReturn = <div>Nessun caso</div>;
            break;
        }
    }

    return htmlToReturn;
};
/**
 * BLOCCO A2
 */
export const getNewsChildren = data => {
    const lng = getCurrentLanguage();
    if (!data || data?.length === 0) return [];

    return data.length > 1
        ? data[2]?.news
              .map(itm => {
                  return {
                      id: itm.id,
                      ...itm.message,
                  };
              })
              .filter(fullMessage => {
                  let data = {};
                  if (fullMessage.translations) {
                      return (
                          fullMessage.translations.subject &&
                          fullMessage.translations.subject[lng] &&
                          fullMessage.translations.subject[lng] !== ""
                      );
                  } else {
                      return fullMessage.subject && fullMessage.subject !== "";
                  }
              })
              .map(fullMessage => {
                  let data = {};
                  if (fullMessage.translations) {
                      data = {
                          id: fullMessage.id,
                          pos: fullMessage.pos,
                          title: fullMessage.translations.subject[lng],
                          text: fullMessage.translations.text[lng],
                          abstract: fullMessage.translations.abstract
                              ? fullMessage.translations.abstract[lng]
                              : fullMessage.translations.text[lng],
                          img: fullMessage.imageurl ? fullMessage.imageurl : null,
                          newType: true,
                      };
                  } else {
                      data = {
                          id: fullMessage.id,
                          pos: fullMessage.pos,
                          title: fullMessage.subject,
                          message: fullMessage.text,
                          abstract: fullMessage.text,
                          img: fullMessage.imageurl ? fullMessage.imageurl : null,
                          newType: false,
                      };
                  }

                  return data;
              })
              .slice(0, N_MAX_NEWS_HOME_CHILDREN)
        : [];
};
/**
 * BLOCCO A3
 */
export const getAdvices = data => {
    const lng = getCurrentLanguage();
    if (!data || data?.length === 0) return [];

    return data.length > 1
        ? data[3]?.news
              .map(itm => {
                  return {
                      id: itm.id,
                      ...itm.message,
                  };
              })
              .filter(fullMessage => {
                  let data = {};
                  if (fullMessage.translations) {
                      return (
                          fullMessage.translations.subject &&
                          fullMessage.translations.subject[lng] &&
                          fullMessage.translations.subject[lng] !== ""
                      );
                  } else {
                      return fullMessage.subject && fullMessage.subject !== "";
                  }
              })
              .map(fullMessage => {
                  let data = {};
                  if (fullMessage.translations) {
                      data = {
                          id: fullMessage.id,
                          pos: fullMessage.pos,
                          title: fullMessage.translations.subject[lng],
                          text: fullMessage.translations.text[lng],
                          abstract: fullMessage.translations.abstract
                              ? fullMessage.translations.abstract[lng]
                              : fullMessage.translations.text[lng],
                          img: fullMessage.imageurl ? fullMessage.imageurl : null,
                          newType: true,
                      };
                  } else {
                      data = {
                          id: fullMessage.id,
                          pos: fullMessage.pos,
                          title: fullMessage.subject,
                          message: fullMessage.text,
                          abstract: fullMessage.text,
                          img: fullMessage.imageurl ? fullMessage.imageurl : null,
                          newType: false,
                      };
                  }

                  return data;
              })
              .sort(sortByPosAscending)
        : [];
};
/**
 * BLOCCO A5
 */
export const getActivitiesAreaA5 = data => {
    const lng = getCurrentLanguage();
    if (!data || data?.length === 0) return [];

    return data[4]?.news
        .map(itm => {
            return {
                id: itm.id,
                ...itm.message,
            };
        })
        .filter(fullMessage => {
            let data = {};
            if (fullMessage.translations) {
                return (
                    fullMessage.translations.subject &&
                    fullMessage.translations.subject[lng] &&
                    fullMessage.translations.subject[lng] !== ""
                );
            } else {
                return fullMessage.subject && fullMessage.subject !== "";
            }
        })
        .map(fullMessage => {
            let data = {};
            if (fullMessage.translations) {
                data = {
                    id: fullMessage.id,
                    pos: fullMessage.pos,
                    title: fullMessage.translations.subject[lng],
                    text: fullMessage.translations.text[lng],
                    abstract: fullMessage.translations.abstract
                        ? fullMessage.translations.abstract[lng]
                        : fullMessage.translations.text[lng],
                    img: fullMessage.imageurl && !getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    file: fullMessage.imageurl && getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    newType: true,
                };
            } else {
                data = {
                    id: fullMessage.id,
                    pos: fullMessage.pos,
                    title: fullMessage.subject,
                    message: fullMessage.text,
                    abstract: fullMessage.text,
                    img: fullMessage.imageurl && !getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    file: fullMessage.imageurl && getIfMediaIsDoc(fullMessage.imageurl) ? fullMessage.imageurl : null,
                    newType: false,
                };
            }

            return data;
        })
        .slice(0, N_MAX_NEWS_HOME);
};

/**CHATS */
export const checkIsCanteenEnabled = canteen_id => {
    if (!canteen_id || canteen_id === "") return false;

    const numberCid = Number(getCidByState(canteen_id));

    const minCanteen = getMinCanteenIDNumber();
    const maxCanteen = getMaxCanteenIDNumber();

    return numberCid >= minCanteen && numberCid <= maxCanteen;
};
export const getChatsFiltered = (chats = [], current_canteen_id = null) => {
    return chats.filter(chat => {
        const cid = chat.data.canteen_id;
        return (checkIsCanteenEnabled(cid) || ENABLED_CHATS.includes(cid)) && cid === current_canteen_id;
    });
};

/**DATE SLIDER */
export const datesEqual = (a, b) => {
    if (!a || !b) return false;

    return a.getTime() === b.getTime();
};
export const checkIfEnabled = (date, daysNoEnabled = [0, 6]) => {
    if (!date || date === "") return false;

    const dayOfWeek = date.getDay();
    return daysNoEnabled.includes(dayOfWeek);
};
export const getFirsXDaysValid = (
    x = 5,
    firstDate = new Date(),
    startFromIndex = 0,
    minus = false,
    daysToDiscard = [0, 6],
) => {
    const dates = [],
        date = [];
    if (minus) {
        /**When clicking left arrow -> go back of 1 week */
        let added = x;
        for (let i = startFromIndex; added > 0; i--) {
            let startDateFormatted = new Date(firstDate);
            startDateFormatted.setDate(startDateFormatted.getDate() + i);
            const momentDate = moment(startDateFormatted);
            if (!checkIfEnabled(startDateFormatted)) {
                dates.push({
                    fullDate: startDateFormatted,
                    date: startDateFormatted.getDate(),
                    day: momentDate.format("dddd"),
                    //month: monthNames[startDateFormatted.getMonth()],
                    month: momentDate.format("MMMM"),
                    year: startDateFormatted.getFullYear(),
                    isWeekend: checkIfEnabled(startDateFormatted),
                });
                added--;
            }
        }
    } else {
        /**When clicking right arrow -> go forward of 1 week */
        let added = 0;
        for (let i = startFromIndex === 0 ? startFromIndex - firstDate.getDay() : startFromIndex; added < x; i++) {
            date[i] = new Date(firstDate);
            date[i].setDate(date[i].getDate() + i);
            const momentDate = moment(date[i]);
            if (!checkIfEnabled(date[i])) {
                dates.push({
                    fullDate: date[i],
                    date: date[i].getDate(),
                    day: momentDate.format("dddd"),
                    //month: monthNames[date[i].getMonth()],
                    month: momentDate.format("MMMM"),
                    year: date[i].getFullYear(),
                    isWeekend: checkIfEnabled(date[i]),
                });
                added++;
            }
        }
    }

    return dates;
};

/**ACTIVITIES PAGE */
export const getActivitiesFiltered = (data, idx = 1) => {
    const lng = getCurrentLanguage();
    if (!data || data?.length === 0) return [];

    return data[idx]?.news
        .map(itm => {
            return itm.message;
        })
        .filter(fullMessage => {
            if (fullMessage.translations) {
                return (
                    fullMessage.translations.subject &&
                    fullMessage.translations.subject[lng] &&
                    fullMessage.translations.subject[lng] !== ""
                );
            } else {
                return fullMessage.subject && fullMessage.subject !== "";
            }
        })
        .sort(sortByPosAscending);
};

/**USER RATE */
/**
 *
 * @param {*} strings - array of strings
 * @returns all strings lowered, otherwise empty
 */
const getAllStringsLowered = (strings = []) => {
    if (!strings || !Array.isArray(strings) || strings.length <= 0) return [];

    return strings.map(str => str.toLowerCase());
};
const checkIfTagVotoIncluded = (strings = []) => {
    if (!strings || !Array.isArray(strings) || strings.length <= 0) return false;

    return strings.filter(str => str.toLowerCase().indexOf(USER_RATE_TAG) !== -1).length > 0;
};
export const getIfUserHasEnabledToRate = (userData = getCurrentUser()) => {
    return (
        userData &&
        userData.tags &&
        Array.isArray(userData.tags) &&
        userData.tags.length > 0 &&
        (getAllStringsLowered(userData.tags).includes(USER_RATE_TAG) ||
            checkIfTagVotoIncluded(getAllStringsLowered(userData.tags)))
    );
};

/**NEWS and ACTIVITIES SLIDER */
export const getBaseOptionsForSlider = () => {
    const options = {
        loop: false,
        autoplay: false,
    };

    if (getIfSoHappy()) {
        Object.assign(options, {
            loop: true,
            autoplay: true,
        });
    }

    return options;
};
